<template>
    <div class="mb-3 form-group">
        <label v-if="label" :class="'label ' + [error ? 'danger-label' : '']">
            {{ label }}
            <span v-if="tooltip_text" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip :title="tooltip_text" class="ms-1"><font-awesome-icon icon="fas fa-circle-info"/></span>
            <BaseBadget 
                class="ms-lg-2 ms-1"
                v-if="showBadget == true"
                :color="badgetColor"
                :text="badgetText"
            />
            <svg v-if="info" @click="showInfo" class="info" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 400c-18 0-32-14-32-32s13.1-32 32-32c17.1 0 32 14 32 32S273.1 400 256 400zM325.1 258L280 286V288c0 13-11 24-24 24S232 301 232 288V272c0-8 4-16 12-21l57-34C308 213 312 206 312 198C312 186 301.1 176 289.1 176h-51.1C225.1 176 216 186 216 198c0 13-11 24-24 24s-24-11-24-24C168 159 199 128 237.1 128h51.1C329 128 360 159 360 198C360 222 347 245 325.1 258z"/></svg>
        </label>
        <div class="file_upload">
            <input
                :disabled="disabled == 1"
                :id="id"
                type="file"
                :class="'form-control ' + [error ? 'danger-border' : '']"
                :required="!!required"
                @input="updateInput"
                :multiple="!!multiple"
                :accept="accept"
                @change="previewFile"
                ref="fileInput"
            />

            <label :for="id" :class="'fake-input ' + [error ? 'danger-border' : ''] + [this.fileNames || previewUrl ? ' selected' : '']">
                <span v-if="this.fileNames || previewUrl" @click.prevent="removeImage" class="remover_mobile"><font-awesome-icon :icon="['fas', 'xmark-large']" /></span>
                <div v-if="preview && previewUrl">
                    <a :href="previewUrl" target="_blank" class="image" :style="'background: #fff url(' + previewUrl + ')'"></a>
                </div>
                <div v-else>
                    <div v-if="short == false" class="image empty">
                        <font-awesome-icon v-if="recommended_size" icon="fa-regular fa-image" style="color: #8f8f8f" />
                        <font-awesome-icon v-else-if="this.fileNames" icon="fa-regular fa-file-check" style="color: #8f8f8f" />
                        <font-awesome-icon v-else icon="fa-regular fa-file" style="color: #8f8f8f" />
                    </div>
                </div>
                <div v-if="short == true">
                    {{ this.fileNames || previewUrl ? 'Arquivo selecionado' : 'Nenhum arquivo' }}
                    <span v-if="recommended_size" class="file_size">Recomendado: {{ recommended_size }}</span>
                    <span v-else-if="max_size" class="file_size">Tamanho máximo: {{ max_size }} </span>
                </div>

                <div v-else>
                    {{ this.fileNames || previewUrl ? 'Arquivo selecionado' : 'Nenhum arquivo selecionado' }}
                    <span v-if="recommended_size" class="file_size">Recomendado: {{ recommended_size }}</span>
                    <span v-else-if="max_size" class="file_size">Tamanho máximo: {{ max_size }} </span>
                </div>

                <span v-if="this.fileNames || previewUrl" @click.prevent="removeImage" class="browse">Remover</span>
                <span v-else class="browse">Selecionar</span>
            </label>
        </div>
        <small v-if="multiple">*Para fazer upload de vários arquivos, utilize o shift enquanto os seleciona.</small>

        <span v-if="!!error" class="invalid-feedback" :class="[error ? 'd-block' : '']" role="alert">{{ error }}</span>

    </div>
</template>

<script>
import { useGlobalStore } from '@/stores/global';

export default {
    emits: ["update:modelValue", "base64"],
    data() {
        return {
            fileNames: null,
            toastIs: true,
            global: useGlobalStore(),
            previewUrl: null,

        }
    },
    props: {
        modelValue: {},

        label: {
            type: String,
            required: false,
            default: null,
        },

        id: {
            type: String,
            required: true
        },

        required: {
            type: Boolean,
            required: false,
            default: null,
        },

        page: {
            type: String,
            required: false
        },

        multiple: {
            type: Boolean,
            required: false,
            default: null,
        },

        accept: {
            type: String,
            required: false
        },

        info: {
            type: String,
            required: false,
            default: null,
        },

        tooltip_text:{
            required:false,
            default: null,
        },

        recommended_size: {
            required: false,
            default: null,
        },

        max_size: {
            required: false,
            default: null,
        },

        showBadget:{
            type: Boolean,
            default: false,
        },

        badgetColor:{
            type: String,
        },
    
        badgetText: {
            type: String,
        },

        preview: {
            required: false,
            default: null,
        },

        base64: {
            type: Boolean,
            required: false,
            default: false,
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },

        short: {
            type: Boolean,
            required: false,
            default: false,
        },

    },
    methods: {
        updateInput(event) {
            if(event.target.files.length > 0){
                let files = event.target.files;
                this.fileNames = null;
                if(files.length > 0) {
                    for(var i=0; i<files.length; i++) {
                        this.fileNames = (this.fileNames) ? this.fileNames + ', ' + files[i].name : files[i].name;
                    }
                }

                if(this.$props.multiple) {
                    this.$emit("update:modelValue", files);
                }else {
                    this.$emit("update:modelValue", event.target.files[0]);
                }

                if(this.global.errors[this.$props.page] && this.global.errors[this.$props.page][this.$props.id]) {
                    delete this.global.errors[this.$props.page][this.$props.id];
                }

                var reader = new FileReader();
                var baseString;
                reader.onloadend = function () {
                    baseString = reader.result;
                };

                reader.readAsDataURL(event.target.files[0])
                setTimeout(() =>{
                    this.$emit('base64', baseString)
                }, 10)
            }
        },

        showInfo() {
            if(this.toastIs) {
                this.toastIs = false;
                this.global.infoMessage(this.$props.info);
                setTimeout(() => {
                   this.toastIs = true;
                }, 6000);
            }
        },

        previewFile(event) {
            if(event.target.files.length > 0){
                if(this.$props.preview == null || this.$props.preview){
                    const file = event.target.files[0];
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = (e) => {
                        this.previewUrl = e.target.result;
                        this.$emit("base64", e.target.result);
                    };
                }else {
                    this.previewUrl = true;
                }
            }
        },

        removeImage(){
            this.$refs.fileInput.value = null
            this.previewUrl = null;
            this.fileNames = null;

            this.$emit("update:modelValue", this.fileNames);
            this.$emit("base64", null);
        },
    },
    computed: {
        error() {
            if(this.global.errors[this.$props.page] && this.global.errors[this.$props.page][this.$props.id]) {
                return this.global.errors[this.$props.page][this.$props.id];
            }
            return null;
        }
    },
    beforeMount() {
        if(this.$props.preview) {
            this.previewUrl = this.$props.modelValue;
        }
    },

    mounted(){
        if(this.$props.modelValue != null || this.$props.modelValue != ''){
            this.fileNames = this.$props.modelValue
        }
    },
};
</script>

<style scoped>
.file_upload {
    position: relative;
    margin-top: 10px;
}

.file_upload input {
    display: none;
}

.label {
    min-height: 21px;
    display: block;
    margin-block: 5px;
    font-size: 14px;
    text-align: left;
    font-weight: 500;
    color: #545454;
    z-index: 2;
}

.fake-input {
    border: 1px #bcbcbc dashed;
    padding: 15px;
    border-radius: 6px;
    font-weight: 400;
    top: 0;
    width: 100%;
    background: #fff;
    z-index: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: flex;
    align-items: center;
    line-height: 20px;
    background-color: #f8f8f8;
}

.fake-input:hover {
    background-color: #f0f0f0;
}

.danger-border {
    border-color: #fd6d6d;
    color: #d60e0e;
}

.danger-label {
    color: #d60e0e;
}

.danger-label svg {
    fill: #d60e0e;
}

.info {
    width: 14px;
    fill: #545454;
    cursor: pointer;
}

.image {
  width: 40px;
  height: 40px;
  overflow: hidden;
  vertical-align: middle;
  background-size: cover;
  border-radius: 4px;
  background-color: #fff;
  background-position: center !important;
  background-size: cover !important;
  border: 1px #e1e1e1 solid;
  margin-right: 15px;
  display: block;
}

.image.empty {
    justify-content: center;
    align-items: center;
    display: flex;
}

.file_size {
    display: block;
    color: #979797;
    font-size: 13px;
}

.browse {
    background-color: #fff;
    padding: 4px 15px;
    font-size: 13px;
    border-radius: 6px;
    border: 1px #d7d7d7 solid;
    height: 30px;
    color: #6c6c6c;
    margin-left: auto;
}

.remover_mobile{
    position: absolute;
    top: 5px;
    right: 5px;
    display: none;
    fill: #6c6c6c;
    width: 20px;
    text-align: center;
}

.selected {
    border-color: #7bc47e;
    background-color: #f2fbf2;
    color: #378b3b;
}

.selected button {
    border-color: #7bc47e;
    background-color: #ffffff;
    color: #378b3b;
}


.selected .image {
    border-color: #7bc47e;
}

.selected .image svg {
    color: #378b3b !important;
}

@media screen and (max-width: 500px){
    .browse {
        display: none;
    }

    .remover_mobile{
        display: block;
    }
}

small {
    display: block;
    font-size: 11px;
}
</style>

